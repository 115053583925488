/* 
.swiper-pagination{
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  gap: 10px;
} */
.swiper{
  padding: 0 5px;
}
.swiper-slide{
  width: auto;
  flex-shrink: 0;
  display: block;
  height: 100%;
  max-height: 100%;
}
.swiper-wrapper{
  max-height: 100%;
  height: 100%;
  display: flex;
  padding: 5px 0;
} 
.swiper-container{
  width: 100%;
  height: 100%;
  padding: 0 5px;
}

.swiper-pagination-bullet{
  width: 20px;
  height: 5px;
  border-radius: 3px;
  margin-top: 20px;
  background-color: var(--chakra-colors-brand-clear);
  transition: .9s ease-in-out;
}

.swiper-pagination-bullet:hover{
  cursor: pointer;
  transform: scale(1.3);
  transition: .7s ease-in-out;
}

.swiper-pagination-bullet-active{
  background-color: var(--chakra-colors-brand-secondary);
}